exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-annual-reports-index-jsx": () => import("./../../../src/pages/annual-reports/index.jsx" /* webpackChunkName: "component---src-pages-annual-reports-index-jsx" */),
  "component---src-pages-beta-index-jsx": () => import("./../../../src/pages/beta/index.jsx" /* webpackChunkName: "component---src-pages-beta-index-jsx" */),
  "component---src-pages-blogs-index-jsx": () => import("./../../../src/pages/blogs/index.jsx" /* webpackChunkName: "component---src-pages-blogs-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-covid-index-jsx": () => import("./../../../src/pages/covid/index.jsx" /* webpackChunkName: "component---src-pages-covid-index-jsx" */),
  "component---src-pages-echo-for-india-index-jsx": () => import("./../../../src/pages/echo-for-india/index.jsx" /* webpackChunkName: "component---src-pages-echo-for-india-index-jsx" */),
  "component---src-pages-education-index-jsx": () => import("./../../../src/pages/education/index.jsx" /* webpackChunkName: "component---src-pages-education-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-finance-index-jsx": () => import("./../../../src/pages/finance/index.jsx" /* webpackChunkName: "component---src-pages-finance-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-growth-report-index-jsx": () => import("./../../../src/pages/growth-report/index.jsx" /* webpackChunkName: "component---src-pages-growth-report-index-jsx" */),
  "component---src-pages-healthcare-index-jsx": () => import("./../../../src/pages/healthcare/index.jsx" /* webpackChunkName: "component---src-pages-healthcare-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/Home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-support-index-jsx": () => import("./../../../src/pages/international-support/index.jsx" /* webpackChunkName: "component---src-pages-international-support-index-jsx" */),
  "component---src-pages-media-contact-index-jsx": () => import("./../../../src/pages/media-contact/index.jsx" /* webpackChunkName: "component---src-pages-media-contact-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-newsletters-index-jsx": () => import("./../../../src/pages/newsletters/index.jsx" /* webpackChunkName: "component---src-pages-newsletters-index-jsx" */),
  "component---src-pages-nursing-index-jsx": () => import("./../../../src/pages/nursing/index.jsx" /* webpackChunkName: "component---src-pages-nursing-index-jsx" */),
  "component---src-pages-partner-index-jsx": () => import("./../../../src/pages/partner/index.jsx" /* webpackChunkName: "component---src-pages-partner-index-jsx" */),
  "component---src-pages-press-releases-index-jsx": () => import("./../../../src/pages/press-releases/index.jsx" /* webpackChunkName: "component---src-pages-press-releases-index-jsx" */),
  "component---src-pages-publications-index-jsx": () => import("./../../../src/pages/publications/index.jsx" /* webpackChunkName: "component---src-pages-publications-index-jsx" */),
  "component---src-pages-reports-index-jsx": () => import("./../../../src/pages/reports/index.jsx" /* webpackChunkName: "component---src-pages-reports-index-jsx" */),
  "component---src-pages-training-index-jsx": () => import("./../../../src/pages/training/index.jsx" /* webpackChunkName: "component---src-pages-training-index-jsx" */),
  "component---src-reusable-templates-blog-page-blog-page-js": () => import("./../../../src/reusable/Templates/BlogPage/blogPage.js" /* webpackChunkName: "component---src-reusable-templates-blog-page-blog-page-js" */),
  "component---src-reusable-templates-event-detail-event-detail-js": () => import("./../../../src/reusable/Templates/EventDetail/eventDetail.js" /* webpackChunkName: "component---src-reusable-templates-event-detail-event-detail-js" */),
  "component---src-reusable-templates-partner-detail-partner-detail-js": () => import("./../../../src/reusable/Templates/PartnerDetail/partnerDetail.js" /* webpackChunkName: "component---src-reusable-templates-partner-detail-partner-detail-js" */)
}

